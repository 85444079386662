import {
  baliBranch,
  jabodetabekAgen,
  jabodetabekBranch,
  jawaBranch,
  jayapuraBranch,
  kalimantanBranch,
  listAgen,
  listBranch,
  ntbBranch,
  sulawesiBranch,
  sumateraAgen,
  sumateraBranch,
} from "./data";
import { useEffect, useState } from "react";
import styles from "./LocationSection.module.css";
import phone from "../../assets/img/home-page/phone.png";
import location from "../../assets/img/home-page/location.png";
import calendar from "../../assets/img/home-page/calendar.png";
import CrossBlue from "../../assets/icons/CrossBlue";
// import { Placeholder } from "react-bootstrap";

const linkSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
<path d="M22 3H29V10M27.5 4.5L20 12M17 5H8C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8V24C5 24.7956 5.31607 25.5587 5.87868 26.1213C6.44129 26.6839 7.20435 27 8 27H24C24.7956 27 25.5587 26.6839 26.1213 26.1213C26.6839 25.5587 27 24.7956 27 24V15" stroke="#ECF6FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

const LocationSection = () => {
  const [activeTab, setActiveTab] = useState("head");
  const [agen, setAgen] = useState("Jabodetabek");
  const [branch, setBranch] = useState("Jabodetabek");
  const [dataAgen, setDataAgen] = useState(jabodetabekAgen);
  const [dataBranch, setDataBranch] = useState(jabodetabekBranch);
  const [agenSidebarOpen, setAgenSidebarOpen] = useState(false);
  const [branchSidebarOpen, setBranchSidebarOpen] = useState(false);

  // * handle agen change
  useEffect(() => {
    switch (agen) {
      case "Jabodetabek":
        setDataAgen(jabodetabekAgen);
        break;
      case "Sumatera":
        setDataAgen(sumateraAgen);
        break;
      default:
        setDataAgen(jabodetabekAgen);
    }
  }, [agen]);

  // * handle branch change
  useEffect(() => {
    switch (branch) {
      case "Jabodetabek":
        setDataBranch(jabodetabekBranch);
        break;
      case "Jawa":
        setDataBranch(jawaBranch);
        break;
      case "Kalimantan":
        setDataBranch(kalimantanBranch);
        break;
      case "NTB":
        setDataBranch(ntbBranch);
        break;
      case "Sulawesi":
        setDataBranch(sulawesiBranch);
        break;
      case "Sumatera":
        setDataBranch(sumateraBranch);
        break;
      case "Bali":
        setDataBranch(baliBranch);
        break;
      case "Jayapura":
        setDataBranch(jayapuraBranch);
        break;
      default:
        setDataBranch(jabodetabekBranch);
    }
  }, [branch]);

  return (
    <section className={styles.container}>
      <div className={styles.tabsContainer}>
        <div className={styles.tabsContent}>
          <h1 className={styles.tabsContentTitle}>Lokasi Kami</h1>
          <ul className={styles.tabsContentItems}>
            <li
              className={styles.tabsContentItem}
              onClick={() => setActiveTab("head")}
              data-state-active={activeTab === "head" ? "true" : "false"}
            >
              Kantor Pusat
            </li>
            <li
              className={styles.tabsContentItem}
              onClick={() => setActiveTab("branch")}
              data-state-active={activeTab === "branch" ? "true" : "false"}
            >
              Kantor Cabang
            </li>
            <li
              className={styles.tabsContentItem}
              onClick={() => setActiveTab("agen")}
              data-state-active={activeTab === "agen" ? "true" : "false"}
            >
              Agen Resmi
            </li>
          </ul>
        </div>
      </div>
      {/* head office */}
      {activeTab === "head" && (
        <div className={styles.hoContainer}>
          <div className={styles.hoImageContainer}>
            <img
              src="/superspring-building.webp"
              alt="Head Office Bilding"
              className={styles.hoImage}
            />
          </div>
          <div className={styles.hoContent}>
            <h2 className={styles.hoContentTitle}>SUPERSPRING BUILDING</h2>
            <ul className={styles.hoContentAddress}>
              <li className={styles.hoContentAddresItem}>
                <img
                  src={location}
                  alt="Location Icon"
                  width={24}
                  height={24}
                />
                <p className={styles.contentAddressItemDesc}>
                  Jl. Cideng Timur No.81, Petojo Sel., Kecamatan Gambir, Kota
                  Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10160
                </p>
              </li>
              <li className={styles.hoContentAddresItem}>
                <img
                  src={calendar}
                  alt="Calendar Icon"
                  width={24}
                  height={24}
                />
                <p className={styles.contentAddressItemDesc}>
                  Opening Hours : Senin - Minggu (24 Jam)
                </p>
              </li>
              <li className={styles.hoContentAddresItem}>
                <img src={phone} alt="Phone Icon" width={24} height={24} />
                <p className={styles.contentAddressItemDesc}>
                  021 - 2206 2222 (Call Center 24 Jam)
                </p>
              </li>
            </ul>
            <div className={styles.hoContentMapWrapper}>
              <div className={styles.hoContentMaps}>
                <iframe
                  title="ho_maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.646944710499!2d106.81063731441841!3d-6.177992662256428!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6a1e1d0b13e24b%3A0xce215af95237e4e6!2sSUPERSPRING%20Building!5e0!3m2!1sid!2sid!4v1667273109636!5m2!1sid!2sid"
                  width="100%"
                  height="100%"
                  allowFullScreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{filter: "invert(120%)"}}
                ></iframe>
              </div>
              <button onClick={() => window.open("https://goo.gl/maps/EtCkNeVXLsDPxP2D6")} className={styles.hoButtonSeeMap}>
                Buka Gmaps{" "}
                <span dangerouslySetInnerHTML={{ __html: linkSVG }}></span>
              </button>
            </div>
          </div>
        </div>
      )}
      {/* branch */}
      {activeTab === "branch" && (
        <>
          <div className={styles.locationWrapper}>
            {/* for dekstop */}
            <div className={styles.locationSidebar}>
              <ul className={styles.locationItems}>
                {listBranch.map((value) => (
                  <li
                    key={value.name}
                    onClick={() => setBranch(value.name)}
                    data-state-active={value.name === branch ? "true" : "false"}
                    className={styles.locationItem}
                  >
                    {value.name}
                  </li>
                ))}
              </ul>
            </div>
            {/* for mobile and tablet */}
            <button
              onClick={() => setBranchSidebarOpen(true)}
              className={styles.locationToogleSidebar}
            >
              {branch}
              <img
                src="/pepicons-pop-list.svg"
                alt="Hamburger Menu"
                width={32}
                height={32}
              />
            </button>
            <div className={styles.locationContent}>
              {dataBranch.map((value) => (
                <article key={value.name} className={styles.cardLocation}>
                  <div className={styles.cardLocationContent}>
                    <h3 className={styles.cardLocationTitle}>{value.name}</h3>
                    <ul className={styles.cardLocationContentItems}>
                      <li className={styles.cardLocationContentItem}>
                        <img
                          src={location}
                          alt="Location Icon"
                          width={24}
                          height={24}
                        />
                        {value.address}
                      </li>
                      <li className={styles.cardLocationContentItem}>
                        <img
                          src={phone}
                          alt="Phone Icon"
                          width={24}
                          height={24}
                        />
                        {value.phone}
                      </li>
                    </ul>
                  </div>
                  <button
                    onClick={() => window.open(value.link)}
                    className={styles.cardLocationButton}
                  >
                    Buka Gmaps{" "}
                    <span dangerouslySetInnerHTML={{ __html: linkSVG }}></span>
                  </button>
                </article>
              ))}
              {/* * loader card component */}
              {/* {[1, 2].map((value) => (
                <article key={value} className={styles.cardLocation}>
                  <div className={styles.cardLocationContent}>
                    <Placeholder as="p" animation="glow">
                      <Placeholder
                        style={{
                          width: "55%",
                          height: "28px",
                          borderRadius: "4px",
                        }}
                      />
                    </Placeholder>
                    <ul className={styles.cardLocationContentItems}>
                      <li className={styles.cardLocationContentItem}>
                        <img
                          src={location}
                          alt="Location Icon"
                          width={24}
                          height={24}
                        />
                        <div style={{ flexGrow: "1" }}>
                          <Placeholder as="p" animation="glow">
                            <Placeholder
                              style={{
                                width: "100%",
                                height: "24px",
                                borderRadius: "4px",
                              }}
                            />
                            <Placeholder
                              style={{
                                width: "90%",
                                height: "24px",
                                borderRadius: "4px",
                              }}
                            />
                          </Placeholder>
                        </div>
                      </li>
                      <li className={styles.cardLocationContentItem}>
                        <img
                          src={phone}
                          alt="Phone Icon"
                          width={24}
                          height={24}
                        />
                        <div style={{ flexGrow: "1" }}>
                          <Placeholder as="p" animation="glow">
                            <Placeholder
                              style={{
                                width: "80%",
                                height: "24px",
                                borderRadius: "4px",
                              }}
                            />
                          </Placeholder>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <Placeholder.Button
                    aria-hidden="true"
                    style={{
                      width: "247px",
                      height: "55px",
                      background: "#44a3db",
                    }}
                  />
                </article>
              ))} */}
            </div>
          </div>
        </>
      )}

      {activeTab === "agen" && (
        <>
          <div className={styles.locationWrapper}>
            {/* for dekstop */}
            <div className={styles.locationSidebar}>
              <ul className={styles.locationItems}>
                {listAgen.map((value) => (
                  <li
                    key={value.name}
                    onClick={() => setAgen(value.name)}
                    data-state-active={value.name === agen ? "true" : "false"}
                    className={styles.locationItem}
                  >
                    {value.name}
                  </li>
                ))}
              </ul>
            </div>
            {/* for mobile and tablet */}
            <button
              onClick={() => setAgenSidebarOpen(true)}
              className={styles.locationToogleSidebar}
            >
              {agen}
              <img
                src="/pepicons-pop-list.svg"
                alt="Hamburger Menu"
                width={32}
                height={32}
              />
            </button>
            <div className={styles.locationContent}>
              {dataAgen.map((value) => (
                <article key={value.name} className={styles.cardLocation}>
                  <div className={styles.cardLocationContent}>
                    <h3 className={styles.cardLocationTitle}>{value.name}</h3>
                    <ul className={styles.cardLocationContentItems}>
                      <li className={styles.cardLocationContentItem}>
                        <img
                          src={location}
                          alt="Location Icon"
                          width={24}
                          height={24}
                        />
                        {value.address}
                      </li>
                      <li className={styles.cardLocationContentItem}>
                        <img
                          src={phone}
                          alt="Phone Icon"
                          width={24}
                          height={24}
                        />
                        {value.phone}
                      </li>
                    </ul>
                  </div>
                  {value?.link && (
                    <button
                      onClick={() => window.open(value.link)}
                      className={styles.cardLocationButton}
                    >
                      Buka Gmaps{" "}
                      <span
                        dangerouslySetInnerHTML={{ __html: linkSVG }}
                      ></span>
                    </button>
                  )}
                </article>
              ))}
            </div>
          </div>
        </>
      )}
      {/* branch sidebar */}
      <div
        style={{
          transform: `${
            branchSidebarOpen ? "translateX(0)" : "translateX(100%)"
          }`,
        }}
        className={styles.sidebarWrapper}
      >
        <div className={styles.sidebarHeader}>
          <h2 className={styles.sidebarTitle}>Lokasi Cabang</h2>
          <button
            className={styles.sidebarCloseButton}
            onClick={() => setBranchSidebarOpen(false)}
          >
            <CrossBlue />
          </button>
        </div>
        <ul className={styles.sidebarItems}>
          {listBranch.map((value) => (
            <li
              key={value.name}
              onClick={() => {
                setBranch(value.name);
                setBranchSidebarOpen(false);
              }}
              data-state-active={value.name === branch ? "true" : "false"}
              className={styles.sidebarItem}
            >
              {value.name}
            </li>
          ))}
        </ul>
      </div>
      {branchSidebarOpen && (
        <div
          onClick={() => setBranchSidebarOpen(false)}
          className={styles.sidebarOverlay}
        ></div>
      )}
      {/* agen sidebar */}
      <div
        style={{
          transform: `${
            agenSidebarOpen ? "translateX(0)" : "translateX(100%)"
          }`,
        }}
        className={styles.sidebarWrapper}
      >
        <div className={styles.sidebarHeader}>
          <h2 className={styles.sidebarTitle}>Lokasi Cabang</h2>
          <button
            className={styles.sidebarCloseButton}
            onClick={() => setAgenSidebarOpen(false)}
          >
            <CrossBlue />
          </button>
        </div>
        <ul className={styles.sidebarItems}>
          {listAgen.map((value) => (
            <li
              key={value.name}
              onClick={() => {
                setAgen(value.name);
                setAgenSidebarOpen(false);
              }}
              data-state-active={value.name === agen ? "true" : "false"}
              className={styles.sidebarItem}
            >
              {value.name}
            </li>
          ))}
        </ul>
      </div>
      {agenSidebarOpen && (
        <div
          onClick={() => setAgenSidebarOpen(false)}
          className={styles.sidebarOverlay}
        ></div>
      )}
    </section>
  );
};

export default LocationSection;
