import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import Dekka10 from "./pages/products/productDetails/dekka10/Dekka10";
import Dekka20 from "./pages/products/productDetails/dekka20/Dekka20";
import Dekka40 from "./pages/products/productDetails/dekka40/Dekka40";
import ScrollToTop from "./helpers/ScrollToTop";
import { Toaster } from "react-hot-toast";
import Location from "./pages/Location";
import Article from "./pages/Article";
import ArticleDetail from "./pages/ArticleDetail";
import Warranty from "./pages/Warranty";
import FloatingWa from "./components/FloatingWa";
import Dekka25 from "./pages/products/productDetails/dekka25/Dekka25";

function App() {
  
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/warranty" element={<Warranty />} />
          <Route path="/product/dk10" element={<Dekka10 />} />
          <Route path="/product/dk20" element={<Dekka20 />} />
          <Route path="/product/dk25" element={<Dekka25 />} />
          <Route path="/product/dk40" element={<Dekka40 />} />
          <Route path="/lokasi" element={<Location />} />
          <Route path="/artikel" element={<Article />} />
          <Route path="/artikel/:slug" element={<ArticleDetail />} />
        </Routes>
        <FloatingWa />
        <Footer></Footer>
      </div>
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            background: "#00182E",
            color: "#ecf6fb",
          },
        }}
      />
    </Router>
  );
}

export default App;
