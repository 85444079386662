import "./Footer.css";
import React from "react";
import dekka from "../../assets/img/footer-dekka.png";
import Instagram from "../../assets/img/dekka-ig.svg";
import Tiktok from "../../assets/img/dekka-tiktok.svg";
import Youtube from "../../assets/img/dekka-youtube.svg";
import Tokopedia from "../../assets/img/dekka-tokped.png";
import Dekka10 from "../../assets/img/footer-DK10.png";
import Dekka20 from "../../assets/img/footer-DK20.png";
import GooglePlay from "../../assets/img/google-play.png";
import AppStore from "../../assets/img/app-store.png";

const Footer = () => {
  return (
    <div className="footer-parent">
      <div className="footer-items">
        <div className="footer-subscribe footer-item">
          <img src={dekka} alt="Dekka" width={297} />
          <p>Aplikasi dapat didapatkan di:</p>
          <div className="footer-subscribe-get-on-store">
            <a
              href="https://play.google.com/store/apps/details?id=cn.com.blackview.dashcam.dekka&hl=id"
              target="_blank"
              rel="noreferrer"
            >
              <img src={GooglePlay} alt="google-play" width={180} />
            </a>
            <a
              href="https://apps.apple.com/id/app/dekka/id6476862258"
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppStore} alt="app store" width={180} />
            </a>
          </div>
        </div>
        <div className="footer-media">
          <div className="footer-store footer-item">
            <h5>Beli Online</h5>
            <a
              href="https://www.tokopedia.com/supersprings"
              target="_blank"
              className="footer-store-item"
              rel="noreferrer"
            >
              <img src={Tokopedia} alt="Tokped" width={33} />
              Tokopedia
            </a>
          </div>
          <div className="footer-socmed footer-item">
            <h5>Media Sosial</h5>
            <a
              href="https://www.instagram.com/superspringid"
              target="_blank"
              className="footer-socmed-item"
              rel="noreferrer"
            >
              <img src={Instagram} alt="IG" width={30} />
              Instagram
            </a>
            <a
              href="https://www.tiktok.com/@superspringid"
              target="_blank"
              className="footer-socmed-item"
              rel="noreferrer"
            >
              <img src={Tiktok} alt="Tiktok" width={30} />
              Tiktok
            </a>
            <a
              href="https://www.youtube.com/channel/UCnCiXRFbOujpXEIqyZTygXg"
              target="_blank"
              className="footer-socmed-item"
              rel="noreferrer"
            >
              <img src={Youtube} alt="Tiktok" width={30} />
              Youtube
            </a>
          </div>
          <div className="footer-product footer-item">
            <h5>Produk</h5>
            <a
              href="https://tokopedia.link/48HriyoFbJb"
              target="_blank"
              className="footer-product-item"
              rel="noreferrer"
            >
              <img src={Dekka20} alt="Dekka40" width={50} />
              Dashcam DK40
            </a>
            <a
              href="https://www.tokopedia.com/supersprings/dashcam-mirror-spion-dekka-dk25-2k-dual-camera-hardwire-kit-memory-included"
              target="_blank"
              className="footer-product-item"
              rel="noreferrer"
            >
              <img src="/product/DK25.webp" alt="Dekka25" width={50} />
              Dashcam DK25
            </a>
            <a
              href="https://tokopedia.link/bvC4SrsFbJb"
              target="_blank"
              className="footer-product-item"
              rel="noreferrer"
            >
              <img src={Dekka20} alt="Dekka20" width={50} />
              Dashcam DK20
            </a>
            <a
              href="https://tokopedia.link/8DexF8uFbJb"
              target="_blank"
              rel="noreferrer"
              className="footer-product-item"
            >
              <img src={Dekka10} alt="Dekka10" width={50} />
              Dashcam DK10
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        Copyright ©{new Date().getFullYear()} PT SUPERSPRING
      </div>
    </div>
  );
};

export default Footer;
