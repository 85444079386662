import "../products-detail.css";
import React, { useState } from "react";
import twoK from "../../../../assets/img/detail/2k-resolution.jpeg";
import wideCameraView from "../../../../assets/img/detail/wide-camera-img.jpeg";
import dayVideo from "../../../../assets/img/detail/day.svg";
import nightVideo from "../../../../assets/img/detail/night.svg";
import videoCamera2 from "../../../../assets/img/detail/video-camera2.svg";
import landscapeLine from "../../../../assets/img/detail/landscape-line.svg";
import Sensor from "../../../../assets/img/home-page/home-sensor.svg";
import Night from "../../../../assets/img/home-page/home-night.svg";
import Hardware from "../../../../assets/img/home-page/home-hardware.svg";
import Carousel, { autoplayPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { Fade } from "react-reveal";
import SendMessage from "../../../../components/SendMessage";
import { Helmet } from "react-helmet";
import DoubleParkingFeature from "../../../../components/DoubleParkingFeature";

const Dekka10 = () => {
  const [videoDefault, setVideoDefault] = useState(true);

  const switchVideo = (video) => {
    if (video === "day") {
      setVideoDefault(true);
    } else {
      setVideoDefault(false);
    }
  };

  const videoBtnDay = {
    backgroundColor: videoDefault ? "#44A3DB" : "",
  };

  const videoBtnNight = {
    backgroundColor: !videoDefault ? "#44A3DB" : "",
  };

  const showVideoDay = {
    display: videoDefault ? "grid" : "none",
  };

  const showVideoNight = {
    display: !videoDefault ? "grid" : "none",
  };

  const cardStyle1 = {
    background: `linear-gradient(180deg, rgba(0, 10, 24, 0.00) 0%, #001128 100%), url(${twoK}) lightgray 50% / cover no-repeat`,
  };

  const cardStyle2 = {
    background: `linear-gradient(180deg, rgba(0, 10, 24, 0.00) 0%, #001128 100%), url(${wideCameraView}) lightgray 50% / cover no-repeat`,
  };

  const linkSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M22 3H29V10M27.5 4.5L20 12M17 5H8C7.20435 5 6.44129 5.31607 5.87868 5.87868C5.31607 6.44129 5 7.20435 5 8V24C5 24.7956 5.31607 25.5587 5.87868 26.1213C6.44129 26.6839 7.20435 27 8 27H24C24.7956 27 25.5587 26.6839 26.1213 26.1213C26.6839 25.5587 27 24.7956 27 24V15" stroke="#ECF6FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;
  return (
    <>
      <Helmet>
        <title>DEKKA - Always On Dashcam - DK10</title>
        <meta
          name="description"
          content="DEKKA Always on Dashcam DK10 adalah dashcam yang canggih untuk meningkatkan keamanan berkendara serta merekam segala aktivitas kendaraan. Dilengkapi dengan hardwire-kit TANPA BIAYA TAMBAHAN agar dashcam langsung terhubung ke kelistrikan aki tanpa melalui lighter port sehingga terus merekam kapan pun dan di mana pun. Always on dashcam DK10 bisa dipasang di mobil, truk/bus, container, dan lain-lain untuk kebutuhan pribadi maupun bisnis."
        ></meta>
      </Helmet>
      <div className="dekka-parent">
        <div className="dekka-heading">
          <Fade>
            <h1>Dashcam DK10</h1>
          </Fade>
          <Fade>
            <a
              className="dk-btn-beli btn"
              href="https://www.tokopedia.com/supersprings/dashcam-dekka-dk10-full-hd-camera-hardwire-memory-included?extParam=src%3Dshop%26whid%3D1156028"
              target="_blank"
              rel="noreferrer"
            >
              <span>Beli DK10</span>
              <div dangerouslySetInnerHTML={{ __html: linkSVG }}></div>
            </a>
          </Fade>
          <Fade>
            <img
              src="/product/DK10.png"
              alt="Deka 10 Banner"
              style={{ aspectRatio: "2/1" }}
            />
          </Fade>
        </div>
        <div className="dekka-scroll-horizontal">
          <Fade left>
            <div className="dekka-card card-1" style={cardStyle1}>
              <div className="dekka-card-content">
                <img src={videoCamera2} alt="Full HD Icon" />
                <h1>Full HD</h1>
                <p>Kualitas video lebih jernih dengan resolusi 1080p.</p>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className="dekka-card card-1" style={cardStyle2}>
              <div className="dekka-card-content">
                <img src={landscapeLine} alt="" />
                <h1>Wide Camera 131°</h1>
                <p>Cakupan area yang terekam lebih luas dan jelas.</p>
              </div>
            </div>
          </Fade>
        </div>
        <div className="dekka-scroll-horizontal-mobile">
          <Carousel
            plugins={[
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 3000,
                },
              },
            ]}
            animationSpeed={1000}
          >
            <div className="dekka-card card-1" style={cardStyle1}>
              <div className="dekka-card-content">
                <img src={videoCamera2} alt="" />
                <h1>Full HD</h1>
                <p>Kualitas video lebih jernih dengan resolusi 1080p.</p>
              </div>
            </div>
            <div className="dekka-card card-1" style={cardStyle2}>
              <div className="dekka-card-content">
                <img src={landscapeLine} alt="" />
                <h1>Wide Camera 131°</h1>
                <p>Cakupan area yang terekam lebih luas dan jelas.</p>
              </div>
            </div>
          </Carousel>
        </div>
        <div className="home-include">
          <div className="include-items">
            <div className="include-items-group">
              <Fade>
                <div className="include-item">
                  <div>
                    <img src="/product/wifi-icon.svg" alt="Wifi Icon" />
                  </div>
                  <h2 className="inclue-item-title">WIFI</h2>
                  <p className="include-item-caption">
                    Memudahkan transfer file rekaman dari unit dashcam ke
                    handphone.
                  </p>
                </div>
              </Fade>
              <div className="include-separator"></div>
              <Fade>
                <div className="include-item">
                  <div>
                    <img
                      src="/product/loop-record-icon.svg"
                      alt="Loop Record Icon"
                    />
                  </div>
                  <h2 className="inclue-item-title">Loop Recording</h2>
                  <p className="include-item-caption">
                    Merekam tanpa henti, dengan cara otomatis menghapus video
                    sebelumnya.
                  </p>
                </div>
              </Fade>
              <div className="include-separator"></div>
              <Fade>
                <div className="include-item">
                  <div>
                    <img src={Hardware} alt="Hardware Icon" />
                  </div>
                  <h2 className="inclue-item-title">Hardwire Kit</h2>
                  <p className="include-item-caption">
                    Memastikan DEKKA aktif merekam 24/7 termasuk saat kendaraan
                    terparkir.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="dk-screen">
          <Fade>
            <div className="dk-screen-img-wraper">
              <img src="/product/dk10-096-screen.png" alt="" />
            </div>
          </Fade>
          <Fade>
            <h2 className="dk-screen-title">0.96 Screen</h2>
            <p className="dk-screen-caption">
              Untuk melihat status dan durasi yang terekam.
            </p>
          </Fade>
        </div>
        <div className="dk-speaker-mic">
          <Fade left>
            <div className="dk-speaker-desc-wraper">
              <h2 className="dk-speaker-title">Speaker & Microphone</h2>
              <p className="dk-speaker-caption">
                Merekam dan mendengarkan aktivitas suara di sekitar kendaraan.
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className="dk-speaker-img-wraper">
              <img src="/product/dk10-speaker-mic.png" alt="" />
            </div>
          </Fade>
        </div>
        <div className="dekka-video-day-night">
          <div className="dekka-video-title">
            <h1>Hasil Rekaman DK10</h1>
            <div className="dekka-video-btn">
              <button
                className="btn-video btn"
                onClick={() => switchVideo("day")}
                style={videoBtnDay}
              >
                <img src={dayVideo} alt="" />
                Day
              </button>
              <button
                className="btn-video btn"
                onClick={() => switchVideo("night")}
                style={videoBtnNight}
              >
                <img src={nightVideo} alt="" />
                Night
              </button>
            </div>
          </div>
          <div style={showVideoDay} className="dk-video-day-night-gallery">
            <video width="100%" autoPlay loop muted>
              <source
                src="https://img.gps.id/dekka/detail/DK10/DK10-DAY.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div style={showVideoNight}>
            <video width="100%" autoPlay loop muted>
              <source
                src="https://img.gps.id/dekka/detail/DK10/DK10-NIGHT.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="dekka-night-vision">
          <Fade>
            <div>
              <img
                src={Night}
                alt="g-sensor icon"
                className="dk-feature-icon"
              />
              <h1>Night Vision</h1>
              <p>Hasil rekaman akan tetap jernih saat malam hari.</p>
            </div>
          </Fade>
          <Fade bottom>
            <video width="100%" autoPlay loop muted>
              <source
                src="https://img.gps.id/dekka/home/night-vision.mp4"
                type="video/mp4"
              />
            </video>
          </Fade>
        </div>
        <div className="dk-core-feature">
          <Fade left>
            <div className="dk-core-feature-card">
              <div>
                <img
                  src={Sensor}
                  alt="g-sensor icon"
                  className="dk-feature-icon"
                />
              </div>
              <h2>G-Sensor</h2>
              <p>
                Otomatis merekam saat terjadi guncangan tanpa harus khawatir
                file terhapus.
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className="dk-core-feature-video-wraper">
              <video width="100%" autoPlay loop muted>
                <source
                  src="/g-sensor-cropped.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </Fade>
        </div>
        <div className="dk-core-feature-container">
          <DoubleParkingFeature />
        </div>
        <SendMessage />
        <div className="dk-slide-up-section">
          <button
            onClick={() => window.scrollTo(0, 0)}
            className="dk-slide-up-button"
          >
            <img src="/arrow-up.png" alt="Arrow Up Icon" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Dekka10;
