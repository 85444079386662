import React from "react";

const WhatsApp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_1856_12395)">
        <path
          fill="#12D31F"
          d="M.856 19.76c0 3.361.884 6.643 2.567 9.535L.695 39.178l10.193-2.652a19.337 19.337 0 009.187 2.32h.009c10.596 0 19.221-8.555 19.226-19.07.002-5.096-1.996-9.888-5.627-13.493-3.63-3.604-8.457-5.59-13.6-5.593C9.486.69.861 9.245.856 19.76"
        ></path>
        <path
          fill="url(#paint0_linear_1856_12395)"
          d="M.167 19.754a19.603 19.603 0 002.659 9.877L0 39.868l10.558-2.747a20.029 20.029 0 009.517 2.405h.009c10.976 0 19.911-8.864 19.916-19.756.002-5.278-2.068-10.242-5.828-13.977C30.412 2.06 25.412.003 20.084 0 9.106 0 .172 8.862.167 19.754zm6.288 9.361l-.394-.62a16.283 16.283 0 01-2.531-8.74c.003-9.053 7.43-16.419 16.56-16.419 4.422.002 8.578 1.713 11.703 4.816 3.126 3.104 4.845 7.23 4.844 11.617-.004 9.054-7.43 16.42-16.553 16.42h-.007a16.632 16.632 0 01-8.425-2.289l-.605-.356-6.265 1.63 1.673-6.059z"
        ></path>
        <path
          fill="#fff"
          d="M15.103 11.496c-.373-.822-.765-.839-1.12-.853-.29-.013-.622-.012-.953-.012-.332 0-.871.124-1.327.618-.456.494-1.742 1.688-1.742 4.118 0 2.43 1.783 4.777 2.032 5.107.249.329 3.442 5.474 8.5 7.453 4.204 1.645 5.06 1.318 5.972 1.235.913-.082 2.945-1.194 3.36-2.347.414-1.153.414-2.141.29-2.348-.125-.205-.457-.329-.954-.576-.498-.246-2.945-1.441-3.4-1.606-.457-.165-.789-.247-1.12.248-.333.493-1.285 1.605-1.576 1.934-.29.33-.58.372-1.078.125-.498-.248-2.1-.769-4.002-2.45-1.48-1.31-2.478-2.926-2.769-3.42-.29-.494-.03-.762.219-1.008.223-.221.498-.576.747-.864.248-.289.33-.495.497-.824.166-.33.083-.618-.042-.865-.124-.247-1.091-2.689-1.534-3.665z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1856_12395"
          x1="2000"
          x2="2000"
          y1="3986.78"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
        <clipPath id="clip0_1856_12395">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsApp
