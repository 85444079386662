import styles from "./FloatingWa.module.css";
import WhatsApp from "../../assets/icons/WhatsApp";
import { useCallback } from "react";

const FloatingWa = () => {
  const handleClick = useCallback(() => {
    window.open("https://wa.me/628111877333")
  }, [])
  
  return (
    <button onClick={handleClick} className={styles.floatingwaWrapper}>
      <WhatsApp />
      <h5>Hubungi Whatsapp</h5>
    </button>
  )
}

export default FloatingWa;
